
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SelectBase from "@/components/Base/SelectBase.vue";
import { useStore } from "vuex";
//import Loading from "@/components/Loading.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
}

export default defineComponent({
  name: "Companies",
  components: {
    //Loading,
    CardBasicNew,
    TableBasicSelect,
    SelectBase,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const router = useRouter();
    const search = ref("");
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.user_preferences.view_type_items);
    const tableButtons = ref([]);
    const originalData = ref([]);
    const company_group_id = ref(null);
    const entity_id = ref(null);
    const offices = ref(null);
    const showDelete = ref(false);
    const prueba = ref(
      !process.env.VUE_APP_API_URL
        ? document.location.origin
        : process.env.VUE_APP_API_URL
    );
    const deleteId = computed(() => store.getters.DeleteId);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.user_preferences.items_per_page,
      total: 0,
    });
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "companies: create") !==
        -1
      );
    });
    const canUpdate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "companies: update") !==
        -1
      );
    });
    const canShow = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "companies: show") !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "companies: delete") !==
        -1
      );
    });
    const catalogs = reactive({
      companiesGroup: [],
      loadingCompaniesGroup: false,
      entities: [],
      loadingEntities: false,
      offices: [],
      loadingOffices: false,
    });
    const fetchCompaniesGroup = async () => {
      catalogs.loadingCompaniesGroup = true;
      const { data } = await ApiService.get("api/companiesGroups/lists");
      catalogs.companiesGroup = data.companiesGroups;
      catalogs.loadingCompaniesGroup = false;
    };
    fetchCompaniesGroup();

    const goToCompany = (id) => {
      router.push({ name: "companiesUpdate", params: { id: id } });
    };

    const fetchEntities = async () => {
      catalogs.loadingEntities = true;
      const { data } = await ApiService.get("api/entities/lists");
      catalogs.entities = data.entities;
      catalogs.loadingEntities = false;
    };
    fetchEntities();

    const fetchOffices = async () => {
      catalogs.loadingOffices = true;
      const { data } = await ApiService.get("api/offices/lists");
      catalogs.offices = data.offices;
      catalogs.loadingOffices = false;
    };
    fetchOffices();

    const header = ref([
      { column: t("iname"), key: "iname" },
      { column: t("icif"), key: "icif" },
      { column: t("company_group"), key: "company_group" },
      { column: t("iwebsite"), key: "iwebsite" },
      { column: t("ientity"), key: "ientity" },
    ]);

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      ApiService.delete(
        `/api/companies/${deleteId.value}?force_delete=true`
      ).then(function () {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          iname: element.name,
          icif: element.cif,
          company_group: element.company_group.name,
          iwebsite: element.website,
          ientity: element.entity?.name,
        };
        return element;
      });
    };

    const getItems = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/companies`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: search.value,
          companies_groups: company_group_id.value,
          reference_entities: entity_id.value,
          offices: offices.value,
          users: [user.id],
        },
      }).then(({ data }) => {
        originalData.value = data.companies;
        dataList.value =
          viewType.value === "card"
            ? createFieldItems(originalData.value)
            : createTableItems(originalData.value);
        filtered.value = dataList.value;
        pagination.value.total = data.pagination.total;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        store.commit("setLoadingStatus", false);
      });
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("companies", [
        { route: "/companies/index", label: "companies" },
      ]);
      getItems();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/companies`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: search.value,
          companies_groups: company_group_id.value,
          reference_entities: entity_id.value,
          offices: offices.value,
        },
      }).then(({ data }) => {
        originalData.value = data.companies;
        dataList.value = createFieldItems(data.companies);
        pagination.value.total = data.pagination.total;
        filtered.value =
          viewType.value === "card"
            ? data.companies
            : createTableItems(data.companies);
        store.commit("setLoadingStatus", false);
      });
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      if (!filtered.value.length) {
        getItems();
      }
    };

    const createElement = () => {
      router.push({ name: "companiesCreate" });
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          iname: element.name,
          icif: element.cif,
          company_group: element.company_group.name,
          iwebsite: element.website,
          ientity: element.entity?.name,
        });
      });
      return result;
    };

    watch(
      () => viewType.value,
      (first) => {
        if (first) {
          filtered.value =
            first === "card"
              ? createFieldItems(originalData.value)
              : createTableItems(originalData.value);
        }
      }
    );

    const actionsButton = (param1, param2) => {
      if (param2 === "EDIT") {
        router.push({ name: "companiesUpdate", params: { id: param1 } });
      }
      if (param2 === "REMOVE") {
        normalDelete(param1);
      }
    };
    const normalDelete = (id) => {
      ApiService.delete("/api/companies/" + id).then(function () {
        getItems();
      });
    };

    return {
      goToCompany,
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      showDelete,
      deleteDialog,
      deleteElement,
      onSearch,
      serverSearch,
      createElement,
      actionsButton,
      currentPageChange,
      setItemsPerPage,
      getItems,
      company_group_id,
      entity_id,
      catalogs,
      offices,
      prueba,
      canCreate,
      canUpdate,
      canShow,
      canDelete,
      user,
    };
  },
});
